import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Map from './Map'
import SearchBox from './SearchBox'
import tw, { css } from 'twin.macro'

export default function Forhandlere() {
  const forhandlerData = useStaticQuery(graphql`
    query {
      allCraftForhandlereForhandlerEntry(sort: { order: ASC, fields: title }) {
        nodes {
          remoteId
          postnr
          title
          omrade
          addresse
          by
          longitude
          latitude
          showroom
          telefon
        }
      }
    }
  `)

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('')
  const [doorsFilter, setDoorsFilter] = useState(false)
  const [activeReseller, setActiveReseller] = useState(null)

  return (
    <section
      className="forhandlere"
      css={[
        css`
          height: 90vh;
          @media (max-width: 768px) {
            height: auto;
            .gm-bundled-control {
              display: none;
            }
          }
        `,
        tw`relative bg-light`,
      ]}
    >
      <Map
        search={search}
        filter={filter}
        forhandlere={forhandlerData.allCraftForhandlereForhandlerEntry.nodes}
        activeReseller={activeReseller}
        setActiveReseller={setActiveReseller}
      />
      <SearchBox
        search={search}
        setSearch={setSearch}
        filter={filter}
        setFilter={setFilter}
        doorsFilter={doorsFilter}
        setDoorsFilter={setDoorsFilter}
      />
    </section>
  )
}
