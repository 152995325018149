import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import mapStyle from './mapStyle.json'
import Markers from './Markers'
import tw from 'twin.macro'

const containerStyle = {
  width: '100%',
  height: '90vh',
}

const center = {
  lat: 56.2250145,
  lng: 10.4217451,
}

const Spinner = () => (
  <div css={tw`h-full w-full text-4xl flex items-center justify-center`}>
    Henter kortmodul...
  </div>
)

export default function MyMap(props) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAubhvMb99Km05kfTaM5Bqq2yqyEsVtzEk',
  })

  const RenderMap = () => {
    const options = {
      styles: mapStyle,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_CENTER,
      },
    }

    return (
      <GoogleMap
        onClick={() => props.setActiveReseller(null)}
        mapContainerStyle={containerStyle}
        center={
          props.activeReseller
            ? {
                lat: parseFloat(props.activeReseller.latitude),
                lng: parseFloat(props.activeReseller.longitude),
              }
            : center
        }
        zoom={props.activeReseller ? 10 : 7}
        options={options}
      >
        <Markers
          forhandlere={props.forhandlere}
          search={props.search}
          filter={props.filter}
          activeReseller={props.activeReseller}
          setActiveReseller={props.setActiveReseller}
        />
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? RenderMap() : <Spinner />
}
