import React from 'react'
import tw, { css } from 'twin.macro'

export default function InputField({ state, setState, placeholder }) {
  return (
    <input
      placeholder={placeholder}
      type="text"
      value={state}
      onChange={(e) => setState(e.target.value)}
      css={[
        tw`font-bold uppercase border-b-2 rounded-md text-center md:w-64 px-4 py-2 mx-4 mt-2 text-sm bg-sand`,
        css`
          @media (max-width: 768px) {
            width: calc(100% - 30px);
          }
          &:focus,
          &:focus-visible {
            outline: none;
          }
        `,
      ]}
    />
  )
}
