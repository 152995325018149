import React from 'react'
import tw, { css } from 'twin.macro'
import Switch from './Switch'
import InputField from './InputField'

export default function SearchBox({ search, setSearch, filter, setFilter }) {
  return (
    <div
      className="searchBox"
      css={[
        tw`md:absolute md:bottom-14 md:right-14 pt-2 bg-white md:rounded-xl`,
        css`
          @media (min-width: 768px) {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.33);
          }
        `,
      ]}
    >
      <InputField
        placeholder="Søg forhandler..."
        state={search}
        setState={setSearch}
      />
      <Switch state={filter} setState={setFilter} fval="1" label="Udstilling" />
      <Switch
        state={filter}
        setState={setFilter}
        fval="noShow"
        label="Uden Udstilling"
      />
      <Switch
        state={filter}
        setState={setFilter}
        fval="2"
        label="STM Udstilling"
      />
      {/* <Switch
        state={filter}
        setState={setFilter}
        fval="3"
        label="STM samarbejdspartnere"
      /> */}
    </div>
  )
}
