import React from 'react'
import { Marker, InfoWindow } from '@react-google-maps/api'
import tw from 'twin.macro'

export default function Markers({
  forhandlere,
  search,
  filter,
  setActiveReseller,
  activeReseller,
}) {
  const infoStyle = {
    background: `white`,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '1rem',
    fontFamily: 'Titillium Web, sans-serif',
  }

  const svgMarker = {
    path: 'M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0',
    fillColor: '#464340',
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 0.3,
    anchor: new window.google.maps.Point(50, 30),
  }
  return forhandlere
    .filter(
      (f) =>
        (f.title?.toLowerCase().includes(search.toLowerCase()) ||
          f.postnr?.toLowerCase().includes(search.toLowerCase()) ||
          f.omrade?.toLowerCase().includes(search.toLowerCase()) ||
          f.adresse?.toLowerCase().includes(search.toLowerCase()) ||
          f.by?.toLowerCase().includes(search.toLowerCase())) &&
        (filter === 'noShow'
          ? f.showroom !== '1' && f.showroom !== '2' && f.showroom !== '3'
          : filter !== ''
          ? filter === f.showroom
          : true)
    )
    .map((f) => {
      return (
        <React.Fragment key={f.remoteId}>
          {activeReseller?.remoteId === f.remoteId && (
            <InfoWindow
              position={{
                lat: parseFloat(f.latitude),
                lng: parseFloat(f.longitude),
              }}
            >
              <div style={infoStyle}>
                <h5>{f.title}</h5>
                <p css={tw`font-bold`}>Tlf. {f.telefon}</p>
                <p>
                  {f.addresse}
                  <br />
                  {f.postnr} {f.by}
                </p>
                {f.showroom === '0' && (
                  <p>( Sælgeren har ikke vinduesudstilling )</p>
                )}
              </div>
            </InfoWindow>
          )}
          <Marker
            onClick={() => setActiveReseller(f)}
            // animation={window.google.maps.Animation.DROP}
            icon={svgMarker}
            title={f.title}
            position={{
              lat: parseFloat(f.latitude),
              lng: parseFloat(f.longitude),
            }}
          />
        </React.Fragment>
      )
    })
}
