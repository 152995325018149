import React from 'react'
import tw, { css } from 'twin.macro'

export default function Switch({ state, setState, label, fval }) {
  return (
    <div className="input" css={tw`relative w-auto flex px-4 my-3`}>
      <button
        onClick={() => {
          setState(state !== fval ? fval : '')
        }}
        type="button"
        className={`lightswitch ${state === fval && 'on'}`}
        role="checkbox"
        aria-checked={state === fval}
        css={css`
          & {
            appearance: none;
            position: relative;
            border: none !important;
            overflow: hidden;
            cursor: pointer;
            user-select: none;
            background-color: lightgrey;
            transition: background-image linear 100ms;
            border-radius: 11px;
            width: 34px;
            height: 22px;
          }
          &:focus {
            outline: none;
          }
          &.on {
            ${tw`bg-stm`}
          }
          &.on .lightswitch-container {
            margin-left: 0;
          }
        `}
      >
        <div
          className="lightswitch-container"
          css={[
            tw`relative`,
            css`
              margin-left: -12px;
              width: 46px;
              position: relative;
              height: 100%;
            `,
          ]}
        >
          <div
            className="handle"
            css={css`
              & {
                border-radius: 10px;
                width: 20px;
                height: 20px;
                left: calc(50% - 10px);
                position: absolute;
                top: 1px;
                background-color: #fff;
              }
            `}
          ></div>
        </div>
      </button>
      <span css={tw`uppercase text-sm font-bold text-stm ml-2`}>{label}</span>
    </div>
  )
}
